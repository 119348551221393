import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';
import { NormalText } from 'components/styled/text.styled';
import { Button } from 'components/styled/button.styled';
import { useNavigate, useParams } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { getDetailOrder, DetailOrderInterface } from 'redux/order';
import { confirmOrder, KonfirmOrderInterface } from 'redux/confirmOrder';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_black.svg';
import InputField from 'components/Form/InputField';
import OrderItem from './order.item';
import moment from 'moment';
import { formatCurrency, notify } from 'helper/general';
import PrintReceipt from 'views/Checkout/Print/print.receipt.components';
import ConfirmationModal from 'components/Confirmation';
import { BillConfirmationIcon } from 'assets/icons';
import { getOrder } from 'redux/order';
import { getOrderInvoice } from 'redux/checkout';

const DetailOrderPage = () => {
  const { id } = useParams();
  const { handlePrint } = PrintReceipt();
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption: { selected_store },
    },
  } = useRedux();
  const navigate = useNavigate();
  const [noRef, setNoRef] = React.useState('');
  const [data, setData] = React.useState<DetailOrderInterface>();
  const [openPrintOption, setOpenPrintOption] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataKonfirm, setDataKonfirm] = React.useState<KonfirmOrderInterface>(); //eslint-disable-line
  const [statusOrderIsKonfirmasi, setStatusOrderIsKonfirmasi] =
    React.useState(false);

  const [dataInvoice, setDataInvoice] = React.useState<any>(); //needs-for get status trx


  React.useEffect(() => {
    setLoading(true);
    let dataPayload = {
      id: id ?? '',
      token: Auth?.data?.access_token ?? '',
    };

    thunkDispatch(getDetailOrder(dataPayload))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          if (res.data && res.data.data) {
            setData(res.data.data);
            setLoading(false);
          }
        }
      })
      .catch((err) => setLoading(false));
  }, [thunkDispatch, id, Auth]);

  React.useEffect(() => {
    setLoading(true);
    if (id) {
      thunkDispatch(getOrderInvoice({ order_number: id?.toString() }))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            if (res.data && res.data.data) {
              setDataInvoice(res.data.data);
              setLoading(false);
            }
          }
        })
        .catch((err) => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Konfirmasi = async () => {
    let dataKonfirm = {
      token: Auth?.data?.access_token ?? '',
      order_number: id ?? '',
      ref: noRef,
    };
    thunkDispatch(confirmOrder(dataKonfirm))
      .unwrap()
      .then(async (res) => {
        if (res && res.status === 'success') {
          if (res.data && res.data.data) {
            setDataKonfirm(res.data.data);
            setLoading(false);
            setOpenPrintOption(true);
          }
        } else if (res && res.status === 'error') {
          if (res.error && res.error.response) {
            notify('error', res.error.response.data.message);
          }
        }
      })
      .catch((err) => notify('error', err.message));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOrderStatusKonfirmasi = () => {
    let split_Url = window.location.href.split('/');
    let orderNumber = split_Url[split_Url.length - 1];

    // Auth &&
    // Auth.data &&
    // Auth.data.profile_data &&
    // Auth.data.profile_data.store_access &&
    // Auth.data.profile_data.store_access.data_store &&
    // // eslint-disable-next-line array-callback-return
    // Auth.data.profile_data.store_access.data_store.map((item) => {
    let dataPayload = {
      store_id: selected_store.id,
      token: Auth?.data?.access_token ?? '',
    };
    thunkDispatch(getOrder(dataPayload))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === 'success') {
          if (res.data && res.data.data) {
            // eslint-disable-next-line array-callback-return
            res.data.data.map((item: any) => {
              if (item.order_number === orderNumber) {
                setStatusOrderIsKonfirmasi(true);
              }
            });
          }
        }
      })
      .catch((err: any) => console.log(err));
    // })
  };

  React.useEffect(() => {
    setLoading(true);
    getOrderStatusKonfirmasi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubTotal = (data: any) => {
    let total = data.product_price * data.qty;
    if (data && data.product_add_on && data.product_add_on.length) {
      let addonsTotal = 0;
      data.product_add_on.forEach((add: any) => {
        addonsTotal += add.qty * data.qty * add.product_price;
      });
      total += addonsTotal;
    }
    return total;
  };

  return (
    <MainLayout
      background="#FFF"
      hasNavbar
      activePage="/order"
      hideBackgroundImage
    >
      <ConfirmationModal
        icon={BillConfirmationIcon}
        open={openPrintOption}
        title={'Cetak Struk Pembelian'}
        hasCancel={false}
        confirmText={'Cetak'}
        handleClose={() => null}
        handleConfirm={async () => await handlePrint('merchant')}
        useCustomButton={
          <Stack
            direction={'column'}
            width={'100%'}
            spacing={1}
            margin={'10px 0 0 0'}
          >
            <Button
              variant={'primary'}
              onClick={async () => await handlePrint('customer')}
            >
              Customer
            </Button>
            <Button
              variant={'primary'}
              onClick={async () => await handlePrint('merchant')}
            >
              Merchant
            </Button>
            {statusOrderIsKonfirmasi ? (
              <Button
                variant={'outline'}
                onClick={() => {
                  notify('success', 'Transaksi Selesai');
                  navigate('/order');
                }}
              >
                Selesai
              </Button>
            ) : (
              <Button
                variant={'outline'}
                onClick={() => setOpenPrintOption(false)}
              >
                Kembali
              </Button>
            )}
          </Stack>
        }
      />
      <Box width={'100%'}>
        <StyledBox>
          <div onClick={() => navigate('/order')} style={{ width: '10%' }}>
            <ArrowIcon fill={'#000'} />
          </div>
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <NormalText
              textAlign={'center'}
              fontSize={'20px'}
              fontWeight={'bold'}
              lineHeight={'24px'}
              margin={'0px 0 0px 0'}
            >
              Transaksi Detail
            </NormalText>
          </div>
        </StyledBox>
        <Navigation>
          <Box width={'100%'} padding={'12px'}>
            <RowDiv>
              <div
                style={{
                  width: '40%',
                }}
              >
                <NormalText
                  textAlign={'left'}
                  fontSize={'15px'}
                  lineHeight={'16px'}
                  margin={'0px 0px 5px 0px'}
                >
                  Nomor Transaksi
                </NormalText>
              </div>
              <NormalText
                textAlign={'left'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0px'}
              >
                {data?.order_number ?? ''}
              </NormalText>
            </RowDiv>
            <RowDiv>
              <div
                style={{
                  width: '40%',
                }}
              >
                <NormalText
                  textAlign={'left'}
                  fontSize={'15px'}
                  lineHeight={'16px'}
                  margin={'0px 0px 5px 0px'}
                >
                  Tanggal
                </NormalText>
              </div>
              <NormalText
                textAlign={'left'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 0px 0px'}
              >
                {data && data.order_date
                  ? moment(new Date(data.order_date)).format('DD/MM/YYYY')
                  : null}
              </NormalText>
            </RowDiv>
            <RowDiv>
              <div
                style={{
                  width: '40%',
                }}
              >
                <NormalText
                  textAlign={'left'}
                  fontSize={'15px'}
                  lineHeight={'16px'}
                  margin={'0px 0px 5px 0px'}
                >
                  Nama | Nomor Telepon
                </NormalText>
              </div>
              <NormalText
                textAlign={'left'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0px'}
              >
                {data?.customer_name ?? ''} | {data?.customer_phone ?? ''}
              </NormalText>
            </RowDiv>
          </Box>
          <ListColor>
            <div
              style={{
                width: '60%',
              }}
            >
              <NormalText
                textAlign={'left'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0px'}
              >
                Produk
              </NormalText>
            </div>
            <div
              style={{
                width: '10%',
              }}
            >
              <NormalText
                textAlign={'center'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0px'}
              >
                Qty
              </NormalText>
            </div>
            <div
              style={{
                width: '30%',
              }}
            >
              <NormalText
                textAlign={'center'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0px'}
              >
                Harga
              </NormalText>
            </div>
          </ListColor>
          {data && data.product_detail && data.product_detail.length !== 0
            ? data.product_detail.map((dataItem, index) => (
                <OrderItem
                  nameProduct={dataItem?.product_name ?? ''}
                  additionalMenu={dataItem.product_add_on}
                  qty={dataItem?.qty ?? 0}
                  image={dataItem?.image_url ?? ''}
                  price={getSubTotal(dataItem)}
                  useLine={
                    data.product_detail.length - 1 === index ? false : true
                  }
                />
              ))
            : null}
          <Box padding={'0 15px'} margin={'10px 0'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <NormalText fontSize="15px">Sub Total</NormalText>
              <NormalText fontSize="15px" fontWeight={'bold'}>
                {formatCurrency(Number(data?.sub_total))}
              </NormalText>
            </Stack>
            <Stack
              direction={'row'}
              margin={'7px 0'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <NormalText fontSize="15px">Tax & Services</NormalText>
              <NormalText fontSize="15px">
                {formatCurrency(Number(data?.total) - Number(data?.sub_total))}
              </NormalText>
            </Stack>
          </Box>
          <ListColor>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <NormalText
                textAlign={'left'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0px'}
              >
                Grand Total
              </NormalText>
              <NormalText
                textAlign={'right'}
                fontWeight={'bold'}
                fontSize={'20px'}
                lineHeight={'16px'}
                margin={'0px 0px 5px 0'}
              >
                {formatCurrency(Number(data?.total) ?? 0)}
              </NormalText>
            </Stack>
          </ListColor>
          <Box width={'100%'} padding={'12px'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              margin={'10px 0'}
            >
              <div>
                <NormalText
                  textAlign={'left'}
                  fontSize={'15px'}
                  lineHeight={'16px'}
                >
                  Pembayaran
                </NormalText>
              </div>
              <NormalText
                textAlign={'left'}
                fontWeight={'bold'}
                fontSize={'15px'}
                lineHeight={'16px'}
              >
                {data?.payment_type === 'debit'
                  ? `Debit Bank ${data.bank_code.replace('_', ' ')}`
                  : data?.payment_type === 'credit'
                  ? `Kredit Bank ${data.bank_code.replace('_', ' ')}`
                  : data?.payment_type === 'cash'
                  ? 'Tunai'
                  : data?.payment_type === 'virtual_account'
                  ? 'Virtual Account'
                  : data?.payment_type === 'qr_code'
                  ? 'QRIS'
                  : data?.payment_type
                  ? data?.payment_type
                  : ''}
              </NormalText>
            </Stack>
            {data &&
              (data.payment_type === 'debit' ||
                data.payment_type === 'credit') && (
                <InputField
                  label={'No Referensi'}
                  type={'text'}
                  onChange={(e: any) => setNoRef(e.target.value)}
                  placeholder={''}
                  value={data?.reference_number}
                  fullBorder={true}
                  boldText={false}
                  widthBox={'100%'}
                  readOnly={true}
                />
              )}
            {statusOrderIsKonfirmasi ? (
              <Button
                width={'100%'}
                type="submit"
                variant={loading ? 'secondary' : 'primary'}
                onClick={() => Konfirmasi()}
                margin={'10px 0'}
                textAlign="center"
              >
                Cetak Bukti Bayar
              </Button>
            ) : (
              dataInvoice?.payment_status !== 'CANCELLED' && dataInvoice?.payment_status !== 'PENDING' &&
              <Button
              width={'100%'}
              type="submit"
              variant={loading ? 'secondary' : 'primary'}
              onClick={() => setOpenPrintOption(true)}
              margin={'10px 0'}
              textAlign="center"
              >
                Cetak Transaksi
              </Button>
            )}
            <Button
              width={'100%'}
              type="submit"
              variant={loading ? 'secondary' : 'outline'}
              border="2px solid white"
              onClick={() => navigate('/order')}
              textAlign="center"
              margin="0 0 100px 0"
            >
              Kembali
            </Button>
          </Box>
        </Navigation>
      </Box>
    </MainLayout>
  );
};

const Navigation = styled.div`
  width: 100%;
  transition: ease-in-out 0.5s;
  height: calc(100vh - 100px);
  text-align: left;
  align-items: center;
  position: relative;
  overflow-x: auto;
  @media only screen and (max-width: 768px) {
    width: 360px;
  }
`;

const StyledBox = styled(Box)`
  height: 40px;
  position: relative;
  flex-direction: row;
  margin: 20px 10px 0px 20px;
  display: flex;
`;

const RowDiv = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  margin: 10px 0;
`;

const ListColor = styled.div`
  width: 100%;
  height: 35px;
  background: #ffee8e;
  flex-direction: row;
  display: flex;
  padding: 10px 10px 20px 20px;
`;

export default DetailOrderPage;
